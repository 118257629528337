.nav-main--burger {
    z-index: inherit;
    height: 15px;
    @include size(125px, 45px);

    // Burger menu Button
    .nav-main-buttons {
        position: relative;

        &__menu {
            @include absolute($top: 0, $right: 0);
            @include size(125px, 45px);
            z-index: 11;
            @include flex($justifyContent: flex-start, $alignItems: center);
            column-gap: 5px;
            margin: 0;
            padding: 9px 23px;
            background-color: $color-main;
            border: 0;
            border-radius: 50px;
            transition: all $duration $timing;
            cursor: pointer;

            &__text {
                font-size: $font-size--text-small;
                line-height: toRem(19);
                font-weight: $font-weight-bold; 
                color: $color-white;
                text-transform: uppercase;
                transition: {
                    property: background-color, border-color, color, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            // Open menu
            &--open {
                opacity: 1;
                visibility: initial;
                pointer-events: auto;

                // Burger menu Button icon
                .nav-main-buttons__menu__icon {
                    position: relative;
                    display: block;
                    @include size(26px);
 
                }

                &:hover, &:focus {
                    background-color: $color-white;

                    .nav-main-buttons__menu__text {
                        color: $color-main;
                    }

                    svg {
                        fill: $color-main;
                    }
                }
                
            }

            &__content--open {
                width: 100%;
                @include flex($direction: row, $alignItems: center, $justifyContent: center);

            }

            &__content--closed {
                width: 100%;
                @include flex($direction: row, $alignItems: center, $justifyContent: center);
                display: none;

            }

            // Close menu
            &--close {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                padding: 9px 19px;

                svg {
                    fill: $color-white;
                    flex: none;
                }

                &:hover, &:focus {
                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }

    }

    // Popup menu
    &__popup {
        position: fixed;
        top: -100vh;
        left: 0;
        right: 0;
        z-index: -1;
        display: flex;
        max-width: inherit;
        width: 100%;
        // height: 220px;
        height: 580px;
        background-color: $color-sixth;
        padding: 0;
        transition: top 0.6s ease-in-out;
    }

    // Section menu (for overflow)
    &__section {
        // overflow-y: auto;
        width: 100%;
        height: 100%;
        padding: 40px 0 20px 0;
    }

    // Container menu
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
    }

    // if Burger menu open
    &.js-active-menu {

        .nav-main-buttons__menu__content {
            &--open {
                display: none;
            }

            &--closed {
                display: flex;
            }
        }

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
            border-width: 1px;
            // transform: translateY(0);
        }

        // Main menu
        .menu {
            display: flex;
            transform: scaleY(1);

            &:after {
                animation: opacity-bar 1.2s ease-in-out 0s 1 normal forwards;

                @keyframes opacity-bar {
                    from {
                        opacity: 0;
                    }
                
                    to {
                        opacity: 1;
                    }
                }
            }
        }

        .menu__link {
            animation: slide-left 1.6s ease-in-out 0s 1 normal forwards;

            @keyframes slide-left {
                from {
                    opacity: 0;
                    transform: translateX(-650px);
                }
            
                to {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 35px;
        width: 345px;
        height: auto;
        margin-top: 115px;
        margin-bottom: 0;
        padding: 0;
        transition: all $duration ease-in-out;
        position: relative;

        &__svg {
            position: absolute;
            width: 15px;
            height: 15px;
            right: 1px;
            top: unset;
            opacity: 0;
            z-index: 1;
            transition: all $duration ease-in-out;

            svg {
                width: 100%;
                height: 100%;
                fill: $color-white;
            }
        }

        &::after {
            content: '';
            position: absolute;
            right: -3px;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: rgba($color-white, 0.1);
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &.menu-item-has-children {
                &:focus-within, 
                &:hover {
                    .submenu {
                        display: none;
                    }
                }

                &.js-active-submenu {
                    .submenu {
                        display: flex;
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: inherit;
            width: 100%;
            height: auto;
            padding: 10px 0;
            color: $color-third;
            font-family: $font-family--heading;
            font-size: toRem(28);
            line-height: toRem(30);
            font-weight: $font-weight-bold;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 25px;
                height: 2px;
                background-color: $color-white;
                transition: width $duration ease-in-out;
            }

            &::after {
                display: none;
            }

            &:hover, &:focus {
                color: $color-white;

                &::before {
                    width: 65px;
                    transition: width $duration ease-in-out;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        position: absolute;
        top: 0;
        right: inherit;
        left: 100%;
        z-index: inherit;
        width: 50vw;
        padding: 10px 0 0 70px;
        background-color: $color-bg--transparent;
        box-shadow: none;

        &__nav {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 5px 60px;
            max-width: inherit;
            padding: 0;
            margin: 0;
            flex-wrap: wrap;
            max-height: 265px;
        }

        &__item {
            width: 300px;
            margin: 0;         
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: auto;
            height: auto;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 9px 0;
            color: $color-third;
            font-weight: $font-weight-medium;
            font-family: $font-family;
            font-size: toRem(20);
            font-weight: $font-weight-normal;
            transition: all $duration ease-in-out;

            &::after {
                display: none;
            }

            &:hover, &:focus {
                text-decoration: underline;
                color: $color-white;

            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }

}

// Button accessibility
button.avoid-main-content {
    position: absolute;
    bottom: 40px;
    right: 40px;
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For Responsive (no scroll on body when menu is open)
body.js-active-navmainburger {
    top: unset!important;
    min-height: unset!important;
}


}

// Button accessibility
button.avoid-main-content {
    @include absolute($bottom: 40px, $right: 40px);
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For no scroll on body when menu is open
body.js-open-menu {
    overflow: hidden;
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main--burger {

        // Main menu
        .menu {
            width: 30%;
            margin-top: 150px;
            padding: 0 30px 0 0;
        }

        // Sub menu
        .submenu {
            width: 65vw;
            padding: 0 30px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {
    
    // Header -- Identity
    .identity.identity--burger {
        position: inherit;
        max-width: 100%;
        height: auto;
    }
    body.js-active-navmainburger .identity {
        margin-top: 0;
        z-index: inherit;
        color: $color-text;
    }

    // Header -- Tools hidden when menu is open
    .js-active-navmainburger .header .tools {
        z-index: 0;
    }

    .js-active-navmainburger .access-sticky {
        z-index: -1;
    }

    // Menu
    .nav-main--burger {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100vw;
        height: 60px;
        border-radius: 20px 20px 0 0;
        background-color: $color-seventh;

        &__button {
            font-size: 0;
            line-height: 0;
            gap: 0;
            padding: 10px;
            border-radius: $border-radius--round;
            margin: 10px 0;
        }

        svg.nav-main__icon {
            fill: $color-third;

        }
        // if Burger menu open
        &.js-active-menu { 

            .nav-main-buttons {
                top: -90dvh;
                bottom: unset;

                &__menu {
                    padding: 0;
                    @include size(40px);
                }

                &__menu__text {
                    display: none;
                }
            }

            // Popup menu
            .nav-main--burger__popup {
                left: 0;
                height: 100dvh;
                z-index: 10;
            }

            // And If submenu open
            .nav-main-buttons.js-active-submenu {
                .nav-main-buttons__submenu {
                    left: -83vw;
                }
            }

            .menu__link {
                animation: none;
            }
        }

        // If submenu open
        &.js-active-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            @include flex($alignItems: center, $justifyContent: flex-start);
            position: absolute;
            bottom: 10px;
            right: calc(5% + 10px);
            padding: 0;
            max-width: 100%;

            // Open menu
            &__menu {
                height: 40px;
                &--open {
                    @include relative($top: auto, $right: auto);
                    width: fit-content;
                    padding: 7px 12px;
                    transition: none;  
                    background-color: $color-bg--transparent;
                    border: $border-style $border-width $color-white;
                }


            }

            // Burger menu responsive - Close submenu
            &__submenu {
                @include absolute($top: -40px, $left: -105vw);
                z-index: 11;
                @include flex($alignItems: center, $justifyContent: center);
                @include size(40px);
                margin: 40px auto 0 40px;
                padding: 0;
                background-color: $color-bg--transparent;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                transition: left $duration $timing;

                svg {
                    @include size(30px);
                    fill: $color-white;
                }
            }
        }

        // Popup menu
        &__popup {
            top: 0;
            right: inherit;
            left: -100vw;
            padding: 0;
            transition: left $duration $timing;

            &::before {
                height: 100px;
            }
        }

        // Section menu
        &__section {
            padding: 0;
        }

        // Container menu
        &__container {
            flex-direction: column;
            max-width: initial;
            gap: 0;
            justify-content: flex-start;
            margin: 0;
            padding: 0;
        }

        // Main menu
        .menu {
            flex-wrap: nowrap;
            @include size(100%, auto);
            min-width: 100%;
            min-height: 100dvh;
            margin: 0;
            padding: 80px 40px 40px;
            gap: 20px;
            overflow: hidden;

            &::after {
                display: none;
            }

            &__item {
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                    }

                    &:focus-within .submenu,
                    &:hover .submenu {
                        display: flex;
                    }

                    &.js-active-submenu {
                        .submenu {   
                            left: 0;
                            display: flex;
                        }
                    }

                    .menu__link::after {
                        display: none;
                    }
                }
            }

            &__link {
                &::before {
                    display: none;
                }
            }
        }

        // Sub menu
        .submenu {
            @include fixed($top: 0, $right: inherit, $left: -100vw);
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 60px 0 0;
            background-color: $color-seventh;
            transition: left $duration $timing;

            &::after {
                display: none;
            }
    
            &__nav {
                @include flex($direction: column, $justifyContent: flex-start, $wrap: nowrap);
                gap: initial;
                @include size(100%, calc(100vh - 100px));
                max-width: 100%;
                max-height: inherit;
                overflow-y: auto;
                padding: 5px 40px 40px;  
            }

            &__item {
                width: 100%; 
            }

            &__link {
                width: 100%;

                &::before {
                    display: none;
                }
            }
        }
    }
}

