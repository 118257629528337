// Button toggler view plan or list
.view {

    &__toggler {
        margin-top: 30px;
    }

    // Title + Toggler button
    &__title {
        font-family: $btn-toggler-view-title-font-family;
        font-size: $btn-toggler-view-title-font-size;
        font-weight: $btn-toggler-view-title-font-weight;
        text-transform: $btn-toggler-view-title-text-transform;
        color: $btn-toggler-view-title-color;
    }

    &__icon {
        @include flex($alignItems: center);
        margin: 0 10px 0 0;
        background-color: $btn-toggler-view-bg;
        border: 1px $btn-border-style $btn-toggler-view-border-color;
        border-radius: $border-radius--round;
        transition: {
            property: transform, background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(20px);
            fill: $btn-toggler-view-color;
            transition: fill $duration $timing;
        }
    }

    &__toggler {
        display: flex;
        align-items: center;

        &:hover, &:focus, &:active {
            .view__icon {
                background-color: $btn-toggler-view-bg--hover;
                border-color: $btn-toggler-view-border-color--hover;
        
                svg {
                    fill: $btn-toggler-view-color--hover;
                }
            }
        }
    }
}

// Container map
.cpt_map {
    margin: 30px 0;

    // Title no result
    .wrapper_map.no-result .map::before {
        font-family: $font-family--heading;
        font-size: $font-size--4;
        font-weight: $font-weight--heading;
    }
}

// Marker popup CPT Events & Contacts
.cpt_map {
    .leaflet-popup-content {
        width: 350px !important;
        font-size: 1rem !important;
    
        .marker-popup {

            p {
                margin: 0;
            }
        
            &__category {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 15px !important;
                padding: $taxo-padding;
                background-color: $taxo-bg;
                border: $taxo-border-width $taxo-border-style $taxo-border-color;
                border-radius: $taxo-border-radius;
                font-family: $taxo-font-family;
                font-size: $taxo-font-size;
                font-weight: $taxo-font-weight;
                line-height: $taxo-line-height;
                text-transform: $taxo-text-transform;
                color: $taxo-color;
            }

            &__title {
                margin-top: 0;
                margin-bottom: 15px;
                padding: 0;
            }

            
            // Events 
            &__dates {
                @include flex($alignItems: center);
                margin-bottom: 15px;

                span {
                    font-family: $font-family--heading;
                    font-size: $font-size--text;
                    font-weight: $font-weight--heading;
                    text-transform: uppercase;
                    color: $color-text;
                }

                svg {
                    flex: none;
                    @include size(20px);
                    fill: $color-text;
                }
            }

            &__location-from {
                @include flex($alignItems: flex-start);
                gap: 5px;
                color: $card--color--second;

                svg {
                    flex: none;
                    @include size(26px);
                    fill: $card--color--second;
                }
            }
    

            // Contacts
            &__phone, &__mail, &__group-address {
                gap: 10px;

                svg {
                    flex: none;
                    @include size(26px);
                    background-color: $btn-action-bg;
                    border: $btn-action-width $btn-action-style $btn-action-border-color;
                    border-radius: $btn-action-border-radius;
                    fill: $btn-action-color;
                    transition: {
                        property: background-color, border-color, fill;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                &:hover, &:focus-within {   
                    svg {
                        background-color: $btn-action-bg--hover;
                        border-color: $btn-action-border-color--hover;
                        fill: $btn-action-color--hover;
                    }
                }
            }

            &__phone, &__mail {
                @include flex($alignItems: center);
                margin-bottom: 10px;
    
                a {
                    color: $color-link;
                }
    
                &:hover, &:focus {
                    a {
                        color: $color-link--hover;
                    }
                }
            }
    
            &__mail {
                margin: 10px 0;

                a {
                    text-decoration: underline;
                    @extend %external_linkAfter;
                    
                    &::after {
                        @include relative($bottom: -1px);
                        float: right;
                        margin-left: 2px;
                        background-color: $card--color;
                    }

                    &:hover, &:focus {
                        text-decoration: none;
                    }
                }
                
            }
    
            &__group-address {
                @include flex($alignItems: flex-start);
                color: $color-text;
            }

            &__itinerary {
                align-items: center !important;
                font-size: $font-size--text !important;

                &::after {
                    @include relative($bottom: -1px, $top: inherit !important);
                }
            }
    
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cpt_map {
        .leaflet-popup-content {
            width: 280px !important;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cpt_map {
        .wrapper_map {
            height: 450px;
        }
    }

}
