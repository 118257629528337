.footer {
    background-color: $color-sixth;
    border-radius: 30px 30px 0 0;

    &__container {
        @include flex($alignItems: center, $justifyContent: flex-start);
        padding: 50px 15px;
    }

    &__logo {
        @include size(240px, 60px);
        margin-bottom: 20px;

        svg {
            @include size(100%);
            fill: $color-white;
        }
    }

    &__infos {
        width: fit-content;
        color: $color-white;
        @include flex($direction: column);
        gap: 15px;
        margin-right: 70px;


    }

    &__opening-hours {
        margin-top: 18px;
        @include flex($direction: column, $justifyContent: center,$alignItems: flex-start);
        gap: 15px;
        color: $color-white;
        margin-right: 100px;
        width: 300px;
        flex: none;
    }

    &__title {
        font-family: $font-family--heading;
        color: $color-white;
        @include font-size(22);
        font-weight: $font-weight-bold;
    }

    &__text {
        color: $color-white;
        font-size: $font-size--text;
        line-height: toRem(24);

    }

    &__phone {
        @include flex($alignItems: center);
        gap: 5px;
        color: $color-white;
        text-decoration: underline;


        svg {
            @include size(26px);
            fill: $color-white;
        }

        &:hover, &:focus {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__btn {
        color: $color-white;
        padding: 9px 22px;
        line-height: toRem(26);

        &:hover, &:focus {
            color: $color-white;
            border-color: $color-white;

            svg {
                fill: $color-white;
            }
        }

        svg {
            @include size(26px);
            fill: $color-white;
        }
    }

    &__reseaux {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        gap: 15px;
        width: 40px;
        margin-right: 55px;

        a {
            @include size(40px);
            border-radius: $border-radius--round;
            background-color: $color-white;
            @include flex( $alignItems: center, $justifyContent: center);
            transition: {
                property: background-color, border-color;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                @include size(30px);
                fill: $color-text;
                transition: {
                    property: fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                background-color: $color-main;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__app {
        @include flex($wrap: wrap,$alignItems: center, $justifyContent: flex-start);
        gap: 20px;
        color: $color-white;
        padding: 35px;
        border: $border-style 0 rgba($color-white, $alpha: 0.2);
        border-left-width: 1px;
        border-right-width: 1px;

        &__top {
            width: 100%;
            @include flex($direction: row, $alignItems: center, $justifyContent: flex-start);
            gap: 20px;

            svg {
                @include size(56px, 56px);
            }
        }

        &__text {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--4;
            line-height: toRem(24);
            width: 200px;
        }

        &__link {
            width: calc(50% - 20px);
            padding: 0;
            border: $border-style $border-width rgba($color-white, $alpha: 0.2);
            border-radius: 10px;
            height: 60px;
            transition: {
                property: border-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                border-color: rgba($color-white, $alpha: 1);
            }

            svg {
                @include size(100%);
            }
        }
    }

    &__menu {
        padding: 30px 0;
        background-color: $color-sixth;

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            width: 100%;
        }

        &__item {
            position: relative;
            padding: 0 20px;

            &::before {
                content: '';
                @include absolute($top: 50%, $right: 0);
                @include size(1px, 12px);
                background-color: rgba($color-white, 0.2);
                transform: translateY(-50%);
            }

            &:last-child::before {
                background-color :transparent;
            }
        }

        &__link {
            color: $color-white;
            font-size: $font-size--text-small;
            line-height: toRem(18);

            &:hover, &:focus {
                color: $color-white;
                text-decoration: underline;
            }
        } 
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

     .footer {

        &__container {
            padding: 50px 10px 30px;
        }

        &__infos {
            margin-right: 50px;
        }

        &__opening-hours {
            margin-right: 35px;
            width: 280px;
        }

        &__reseaux {
            margin-right: 40px;
        }

        &__app {
            flex-direction: column;
            padding: 0 20px 0 35px;
            align-items: flex-start;

            &__top {
                flex-direction: column-reverse;
                align-items: flex-start;

            }

            &__link {
                @include size(140px, 60px);
            }
        }

        &__menu {
            &__list {

            }

            &__item {
                padding: 0 15px;
            }
        }

     }

}


// 960
@media screen and (max-width: $medium) {

    .footer {
        &__container {
            flex-wrap: wrap;
            row-gap: 40px;
        }

        &__infos {
            width: 50%;
            margin: 0;
        }

        &__btn {
            width: 210px;
        }

        &__opening-hours {
            width: calc(50% - 10px);
            margin: 0;
        }

        &__reseaux {
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            margin-right: 0;
            height: 40px;
        }

        &__app {
            width: 100%;
            padding: 35px 0;
            border-width: 1px 0;
            gap: unset;
            flex-direction: row;
            justify-content: space-between;

            &__top {
                flex-direction: row-reverse;
                width: 50%;
                justify-content: flex-end;
            }

            &__link {
            }
        }

        &__menu {
            padding-bottom: 105px;
            &__list {
                justify-content: flex-start;
            }

            &__item {
                padding: 0 30px 0 0;
                position: relative;
                margin-right: 30px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .footer {
        &__infos,
        &__opening-hours {
            flex: none;
            width: 100%;
        }

        &__app {
            flex-direction: row;
            justify-content: flex-start;
            gap: 20px;

            &__top {
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
            }
        }

        &__menu {

            &__list {
                row-gap: 20px;
            }
        }
    }

}
