//======================================================================================================
// Global Colors
//======================================================================================================

// SVG
$color-svg--consultations               : $color-svg; // single consultations


//======================================================================================================
// Card
//======================================================================================================
$card-consultations-v1-gap              : 0; // 0 si le padding général est égal à 0 (voir dans le fichier variables.scss)

// Background Card
$color-bg--vote-v1                      : $color-main;
$color-bg--vote-v2                      : $color-main--rgba;

// Text Card
$color--vote                            : $color-white; // Consultations card archive