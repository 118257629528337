.page-pagination {
    text-align: center;
    margin: 0 0 30px 0;

    p {
        margin-top: 15px;
        margin-bottom: 10px;
        font-weight: $font-weight-bold;
        font-size: $font-size--text-small;
        color: $color-text--light;
        text-transform: uppercase;
    }
}

// For archive button see more post
button#load-older-posts {
    transition: {
        property: background-color, color, border-color, opacity;
        duration: $duration;
        timing-function: $timing;
    }
}


//======================================================================================================
// Responsive 
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .page-pagination {     
        margin-top: 15px;
    } 

} 
