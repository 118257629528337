header {
    position: relative;
    background-color: $color-seventh;
    height: 120px;
    border-radius: 0 0 20px 20px;
    z-index: 15;

    &::before {
        content: '';
        @include absolute($top: 0, $left: 0, $right: 0);
        z-index: 0;
        height: 120px;
        background-color: $color-seventh;
        border-radius: 0 0 20px 20px;
    } 

    > .container {
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        height: 100%;
    }

    @keyframes headerFixed {
        from {
            top: -120px;
        }
        to {
            top: 0;
        }
    }

    &.header--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 85px;
        width: 100%;
        z-index: 10;
        animation: .5s headerFixed;
        
        &::before {
            height: 85px;
        }


        & ~ .content-wrapper {
            margin-top: 85px;
        }

        > .container {
            height: 100%;
            align-items: center;
        }
        
    }

}

.home .header {
    background-color: $color-bg--transparent;

    &::before {
        background-color: $color-bg--transparent;
    }

    &--fixed {
        background-color: $color-seventh;

        &::before {
            background-color: $color-seventh;
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(240px, 60px);
    z-index: 1;
    color: $color-text;
    transition: color $duration $timing;

    &:hover, &:focus {
        color: $color-second;
    }

    &__title {
        @include size(100%);
    }
}


//======================================================================================================
// Profils
//======================================================================================================

.profils {
    position: relative;
    margin: 0;
    z-index: 6;
    width: fit-content;

    &__button {
        @include flex($alignItems: center, $justifyContent: space-between);
        @include size(220px, 45px);
        position: relative;
        padding: 0 15px 0 25px;
        background-color: $color-bg--transparent;
        border: solid 1px $color-white;
        border-radius: 50px;
        color: $color-white;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        z-index: 2;

        transition: {
            property: background-color, border-color, color;
            duration: $duration;
            timing-function: $timing;
        };

        &:hover, &:focus {
            border-color: $color-white;
            color: $color-text;
            background-color: $color-white;
            
            .profils__picto {
                fill: $color-dark;
            }
        }

        &--open {
            border-radius: 20px 20px 0 0;
            border-bottom-color: $color-second;
            color: $color-text;
            background-color: $color-white;

            &:hover, &:focus {
                border-bottom-color: $color-second;
            }
            .profils__picto {
                transform: rotate(180deg);
                fill: $color-second;
            }

        }


        &--close {
            display: none;
        }
    }

    &__picto {
        @include size(20px, 20px);
        fill: $color-white;
        transition: transform $duration $timing;
    }

    &__list {
        position: absolute;
        top: 25px;
        left: 0;
        @include flex($direction : column);
        max-height: 0;
        width: 220px;
        padding: 30px 25px 15px;
        background-color: $color-white;
        border-radius: 0 0 20px 20px;
        overflow: hidden;
        opacity: 0;
        /* transition: {
            property:  max-height;
            duration: .75s;
            timing-function: $timing;
        }; */
        color: $color-text;
        z-index: -1;
        &:last-child {
            border-bottom: none;    
        }
        &--open {
            opacity: 1;
            height: fit-content;
            max-height: 100vh;
        }
    }

    &__item {
        width: 100%;
        padding: 5px 0;
        border-bottom: solid 1px $color-light;
        transition: opacity $duration $timing;

        &:hover, &:focus {
            text-decoration: underline;
        }

        &:last-child {
            border-bottom: none;    
        }
    }

}


//======================================================================================================
// Tools
//======================================================================================================
.tools {
    z-index: 2;
    width: 90px;
    margin-left: auto;

    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        @include size(90px, 40px);
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: $color-bg--transparent;
        pointer-events: auto;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px);
        padding: 0;
        margin: 0;
        background-color: $color-main;
        border: 0;
        border-radius: $border-radius--round;
        cursor: pointer;

        svg {
            @include size(30px);
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-white;

            svg {
                fill: $color-main;
            }
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }
}


//======================================================================================================
// sticky en un clic
//======================================================================================================
.access-sticky {
    @include position(fixed, $bottom: 0, $right: -360px);
    @include size(360px, 100dvh);
    @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
    gap: 30px;
    z-index: 50;
    padding: 30px;
    background-color: $color-fourth;
    border-radius: $border-radius 0 0 $border-radius;
    transition: {
        property: right;
        duration: 0.5s;
        timing-function: $timing;
    }

    &__button {

        &--open {
            @include position(fixed, $bottom: 230px, $right: 0);
            @include flex($direction: row, $alignItems: center);
            background-color: $color-fifth;
            border-radius: 50px 0 0 50px;
            margin: 0;
            padding: 0 10px 0 4px;
            @include size(85px);
            color: $color-white;
    
            svg {
                fill: $color-white;
                @include size(24px);
                flex: none;
                pointer-events: none;

            }

            &:hover, &:focus {
                background-color: $color-white;
            }
        }

        &--close {
            @include absolute($top: 30px, $right: 40px);
            display: flex;
            align-items: center;
            justify-content: center;
            @include size(40px);
            background-color: $color-bg--transparent;
            margin: 0;
            padding: 0;

            svg {
                @include size(30px);
                fill: $color-fifth;
                transition: fill $duration $timing;
            }

            &:hover, &:focus {
                background-color: $color-white;
            }
        }


    }

    &__text {
        font-family: $font-family--heading;
        font-size: toRem(11);
        line-height: 1;
        font-weight: $font-weight-bold;
        pointer-events: none;

        &--bottom {
            font-family: $font-family--heading;
            font-size: toRem(18);
            line-height: 1;
            font-weight: $font-weight-bold;
            pointer-events: none;

        }
    }

    &__title {
        display: block;
        font-family: $font-family--heading;
        font-size: toRem(30);
        line-height: toRem(35);
        font-weight: $font-weight-bold;
        color: $color-fifth;
        margin-top: 5px;
    }

    &__wrapper {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: space-between);
        gap: 20px;
        width: 100%;
    }

    &__item {
        width: calc(50% - 10px);
        @include size(140px, 130px);
    
        svg {
            @include size(140px, 100px);
            border: $border-style $border-width rgba($color-fifth, 0.2);
            border-radius: $border-radius;
            background-color: $color-bg--transparent;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            path[fill="#FBE8F4"] {
                fill: rgba($color-fifth, 0.2);
                transition: fill $duration $timing;
            }                
        }

        &:hover, &:focus {
            svg {
                border-color: $color-fifth;

                path[fill="#FBE8F4"] {
                    fill: $color-white;
                }  
            }

        }

        &__text {
            text-align: center;
            font-family: $font-family;
            font-size: $font-size--text-small;
            line-height: toRem(18);
            font-weight: $font-weight-semibold;
            margin-top: 5px;
        }
    }


    &.js-active-sticky {        
        right: 0;

        .access-sticky {
            &__button {

                &--open {
                    display: none;
                }
            }
        }

    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .tools {
        &__container {
            padding: 0;
            margin-right: 10px;
        }
    }
    .profils {
        &__button {
            margin:  0 10px 0 0;
        }
    }


}


// 960
@media screen and (max-width: $medium) {

    .header {
        > .container {
            justify-content: center;
            height: 110px;
            gap: 0;
        }

        &__content {
            width: 0;
        }
    
        // Identity
        .identity {
        }
    
        // Tools
           .tools {
            position: fixed;
            width: 90px;
            height: 40px;
            bottom: 10px;
            right: calc(5% + 120px);
            z-index: 12;

            &__container {
                @include size(100%);
                max-width: 100%;
                margin: 0;
            }

            &__content {
                margin: 0;
            }

            &__item {
                @include size(40px);
                margin: 0;
                background-color: $color-bg--transparent;
                border: $border-style $border-width $color-white;
                transition: background-color $duration $timing;

                &:hover, &:focus {
                }

            }
        }

    } 

    .profils {
        width: 100%;
        z-index: 0;

        &__button {
            align-items: center;
            color: $color-third;
            display: flex;
            font-family: $font-family--heading;
            font-size: toRem(28);
            font-weight: $font-weight-bold;
            text-transform: none;
            height: auto;
            justify-content: space-between;
            line-height: toRem(30);
            max-width: inherit;
            padding: 10px 0;
            position: relative;
            width: 100%;
            border: 0;
            z-index: 0;
            transition: none;

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                color: $color-white;
            }

            &:hover, &:focus {
                color: $color-white;
                background-color: $color-bg--transparent;
            }

            &--close {
                @include flex($alignItems: center, $justifyContent: center);
                @include size(40px);
                align-items: center;
                background-color: $color-bg--transparent;
                border: 1px solid $color-white;
                border-radius: $border-radius--round;
                padding: 0;
                margin: 0;
                position: absolute;
                top: 3dvh;
                z-index: 11;

                &:hover, &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-main;
                    }
                }

                svg {
                    display: block;
                    fill: $color-white;
                    @include size(30px);
                }
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: nowrap;
            justify-content: flex-start;
            overflow-y: auto;
            padding: 60px 40px 40px;
            background-color: $color-seventh;
            height: 100vh;
            left: -100vw;
            position: fixed;
            row-gap: 30px;
            top: 0;
            transition: left .25s ease-in-out;
            width: 100vw;
            z-index: 1;


            &--open {
                left: 0;
            }
        }

        &__item {
            align-items: center;
            color: $color-third;
            display: flex;
            font-size: 1.25rem;
            font-weight: 400;
            height: auto;
            margin-bottom: 1px;
            padding: 9px 0;
            position: relative;
            transition: all .25s ease-in-out;
            width: 100%;
            border: 0;

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                color: $color-white;
                text-decoration: none;
            }
        }

        &__picto {
            display: none;
        }
    } 

    .js-open-menu {
        .tools {
            z-index: 10;
        }
    }

    .access-sticky {
        bottom: -580px;
        right: 0;
        border-radius: $border-radius $border-radius 0 0;
        left: 0;
        @include size(100vw, 580px);
        padding-bottom: 50px;        
        transition: {
            property: bottom;
            duration: $duration;
            timing-function: $timing;
        }
    
        &.js-active-sticky {
            bottom: 0;
        }

        &__button {
            &--open {
                @include position(fixed, $bottom: 0, $right: unset, $left: calc(5% + 10px));
                height: 70px;
                flex-direction: column;
                border-radius: 50px 50px 0 0;
                padding: 4px 21px 10px;

                svg {
                    transform: rotate(90deg);
                }
            }
        }

        &__wrapper {
            justify-content: center;
        }

        &__item {
            @include size(140px, 130px);
        }

    }
}


// 640
@media screen and (max-width: $small) {

    .header {
        height: 100px;

        &::before {
            height: 100px;
        }
    }

    // Tools
    .tools {
        &__container {
            top: -6px;
        }

        &__item {
            margin: 0 5px;
        }
    }

    // .tools-view {
    //     &__container {
    //         .profils {
    //             margin-top: 80px;
    //             margin-right: 35px;
    //         }
    //     }
    // }

}
