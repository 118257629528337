//======================================================================================================
// Bloc Map
//======================================================================================================
.bloc-map {
    margin: 30px 0;
}

 .maps-container .creasit-marker, .bloc-map .creasit-marker {
     background-image: url('/wp-content/themes/'+ $theme_name +'/assets/src/images/custom-marker.png');
 }


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-map {
        .wrapper_map {
            height: 430px;
        }
    }
}
