.cover {
    position: relative;
    background-color: $color-light;
    @include flex($direction: column);
    padding-top: 55px;
    margin-top: -15px;
    padding-bottom: 20px;

    &::after {
        content: "";
        @include size(100%, 20px);
        background-color: $color-white;
        border-radius: $border-radius $border-radius 0 0;
        @include absolute($bottom: 0, $left: 0);
    }

    &--404, &--search {
        padding-bottom: 40px;
    }



    > .container {
        width: 100%;
        @include flex($direction: row, $alignItems: center);
    }

    &__informations {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: center);
        gap: 15px;
        width: 100%;
        padding: 40px;
        margin-left: -40px;
        background-color: $color-white;
        border-radius: $border-radius;
        box-shadow: 1px 1px 20px 0px #2828281A;

    }
    
    &__image-wrapper {
        width: 620px;
        height: 380px;
        background-color: $color-bg--image;
        border-radius: $border-radius;
        overflow: hidden;
        flex: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__text {
        width: 100%;
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: space-between);
    }



    &__title {
        color: $color-main;
        font-weight: $font-weight-bold;
        font-size: $font-size--7;
        margin: 0;
    }

    &__lead-wrapper {
        p {
            margin: 0;
            font-weight: $font-weight-bold;
            font-size: $font-size--5;
            line-height: 26px;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cover {
        > .container {
            flex-direction: column;
        }

        &__title {
            font-size: toRem(36);
        }

        &__informations {
            margin: -40px 0 0 0;
            padding: 25px;
        }

        &__image-wrapper {
            width: 100%;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }
    }

}
