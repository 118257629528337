.front-page {
    position: relative;
    z-index: 1;
    margin-top: -30px;
    background-color: $color-white;
    border-radius: 30px 30px 0 0;
    display: inline-block;
    width: 100%;
}

.container--home {
    @include flex($wrap: wrap);
    width: $large;
}

.home {

    &__title {
        padding: 0;
        margin: 0;
        font-family: $font-family--heading;
        font-size: toRem(60);
        line-height: toRem(45);
        font-weight: $font-weight-bold;
        @include flex($direction: column);

        &::before {
            display: none;
        }
    }

    &__subtitle {
        color: $color-dark;
        font-size: toRem(30);
        line-height: toRem(30);
        font-weight: $font-weight-light;
        padding-left: 40px;
        position: relative;
        text-transform: lowercase;

        &::before {
            content: "";
            @include absolute($top: 50%, $left: 0);
            z-index: 0;
            @include size(30px, 1px);
            background-color: $color-dark;
            transform: translateY(-50%);
        }
    }

    //======================================================================================================
    // home search 
    //======================================================================================================

    .home-search {

        .search-popup__content {
            position: absolute;
            top: 400px;
            width: 470px;
            height: 50px;
            margin: auto;
            left: 0;
            right: 0;
            z-index: 5;
        }

        .search-container {
            z-index: 5;
            input {
                background: rgba($color-white, 1);
                border-radius: 8px;
                border: $border-width $border-style $color-white;
                padding: 0 20px;
                height: 50px;
                z-index: 2;
                appearance: none;

                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;
                }

                &:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-search-cancel-button {
                    opacity: 0;
                }

            }

            &-open {
                input {
                    background-color: $color-white;
                }
            }

            .search-svg {
                z-index: 3;
                width: auto;
                height: 40px;
                width: 40px;
                background-color: transparent;
                border: 0;

                svg {
                    width: 30px;
                    height: 30px;
                    fill: $color-dark;
                }
            }
        }

        .searchResult {
            position: absolute;
            background: $color-white;
            top: 25px;
            padding-top: 25px;
            width: 100%;
            border-radius: 0 8px 8px;
            overflow: hidden;
            box-shadow: $shadow;
            z-index: 1;

            li {
                background-color: $color-bg--transparent;
                border-color: $color-bg--transparent;
                display: none;

                &:first-child {
                    border-top: 1px solid $color-gray;
                }

                a {
                    padding: 15px 110px 15px 24px;
                }

                &:nth-child(1), &:nth-child(2), &.show-all {
                    display: block;
                }

            }
        }
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        position: relative;
        margin-top: -120px;
        height: 580px;
        z-index: 0;

        &::before {
            content: "";
            @include absolute($top: 0, $left: 0);
            z-index: 2;
            @include size(100%);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 55%);
            pointer-events: none;
        }

        > .container {
            position: relative;
        }

        .container {
            &--content {
                position: relative;
                height: 100%;
            }

            &--navigation, &--pagination {
                @include absolute(0, 0, 0, 0);
                height: 100%;
                pointer-events: none;
            }
        }

        .slideshow {
            @include size(100%, 100%);

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                @include size(100%);
            }

            &__container {
                display: inherit;
                position: relative;
                @include size(100%);
            }

            &__content {
                @include flex($direction: column);
                box-sizing: border-box;
                @include absolute($right: 30px, $bottom: 50px);
                @include size(500px, auto);
                padding: 20px;
                background-color: $color-bg-caption;
                color: $color-caption;
            }

            &__title {
                margin-bottom: 15px;
                font-size: $font-size--4;
                font-weight: $font-weight-bold;
            }

            &--video {
                img {
                    display: none;
                }
            }

        // Pagination (bullet)
        .c-pagination {
            @include absolute($bottom: 90px, $right: 15px);
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 10px;
            height: 25px;
            padding: 6px 10px;
            background-color: #12333580;
            border-radius: $btn-spe-border-radius;
            z-index: 2;
            pointer-events: auto;
            backdrop-filter: blur(3px);

            .nav-swiper-autoplay {
                @include size(12px);
                padding: 0;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                margin: 0;

                &__icon {
                    @include size(12px);
                    fill: $color-white;
                }

                &:hover,
                &:focus {
                    .nav-swiper-autoplay__icon {
                        fill: $color-white;
                    }
                }
            }

            &__bullets {
                @include flex($alignItems: center);
                gap: 10px;

                .swiper-pagination-bullet {
                    @include size(12px);
                    margin: 0 !important;
                    opacity: 1 !important;
                    background-color: $color-bg--transparent;
                    border: 1px solid $color-white;
                    border-radius: 30px;
                    transition: {
                        property: width, background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover,
                    &:focus{                    
                        background-color: $color-white;
                        border-color: $color-white;
                    }

                    &-active {
                        width: 22px;
                        background-color: $color-white;
                        border-color: $color-white;
                    }
                }
            }
        }

        .nav-swiper-autoplay {
            &--pause {
                .nav-swiper-autoplay__icon {
                    &--pause {
                        display: none;
                    }
                    &--play {
                        display: block;
                    }
                }
            }
            
            &--play {
                .nav-swiper-autoplay__icon {
                    &--play {
                        display: none;
                    }
                    &--pause {
                        display: block;
                    }
                }
            }
        }
        
        }
    }

    //======================================================================================================
    // Access
    //======================================================================================================
    .access {
        background-color: $color-white;
        padding: 32px 65px;
        border-radius: 40px;
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        margin-top: -40px;
        z-index: 5;
        position: relative;
        
        &__item {
            @include size(140px, 130px);
            
            svg {
                @include size(140px, 100px);
                border: $border-style $border-width $color-fourth;
                border-radius: $border-radius;
                background-color: $color-bg--transparent;
                transition: {
                    property: background-color, border-color, color, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }

                path[fill="#FBE8F4"] {
                    transition: {
                        property: fill;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }  
                
            }

            &:hover, &:focus {
                svg {
                    background-color: $color-fourth;
                    border-color: $color-fifth;

                    path[fill="#FBE8F4"] {
                        fill: $color-white;
                    }  
                }

            }
        }

        &__text {
            text-align: center;
            font-family: $font-family;
            font-size: $font-size--text-small;
            line-height: toRem(18);
            font-weight: $font-weight-semibold;
            margin-top: 5px;
        }
    }

    //======================================================================================================
    // News
    //======================================================================================================
    .news {
        padding-top: 290px;
        padding-bottom: 120px;
        margin-top: -155px;
        background-color: $color-light;
        border-radius: 30px 30px 0 0;

        &__title {
            margin: 0 0 -105px 750px;
            height: 80px;
        }

        &__posts {
            @include flex($direction: column, $justifyContent: space-between);
            gap: 40px;
        }

        &__bloc {
            @include size(100%, 370px);
            @include flex($direction: row, $alignItems: flex-end);

            &:hover, &:focus {
                .news__text svg {
                    fill: $color-white;
                    background-color: $color-main;
                }

                .news__image img {
                    transform: scale(1.05);

                }
            }
        }

        &__text {
            @include size(500px, 200px);
            margin: 0 0 35px -30px;
            border-radius: $border-radius;
            background-color: $color-white;
            color: $color-dark;
            font-size: $font-size--2;
            line-height: toRem(36);
            padding: 60px 50px;
            position: relative;
            box-shadow: 1px 1px 20px 0px #2828281A;

            svg {
                @include absolute($bottom: 10px, $right: 10px);
                @include size(20px);
                fill: $color-main;
                background-color: rgba($color-light, 0.7);
                border-radius: $border-radius--round; 
                transition: {
                    property: background-color, fill, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }

        &__link {
            &:hover, &:focus {
                .news__image svg {
                    fill: $color-white;
                    background-color: $color-main;
                }

                .news__image img {
                    transform: scale(1.05);

                }
            }
        }
        
        &__image {
            border-radius: $border-radius;
            @include size(470px, 250px);
            overflow: hidden;
            position: relative;

            img {
                @include size(100%);
                object-fit: cover;
                transform: scale(1);
                transition: {
                    property: transform;
                    duration: 0.5s;
                    timing-function: $timing;
                }
            }

            svg {
                @include absolute($bottom: 10px, $right: 10px);
                @include size(20px);
                fill: $color-main;
                background-color: rgba($color-light, 0.7);
                border-radius: $border-radius--round; 
                transition: {
                    property: background-color, fill, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &--large {
                @include size(700px, 370px);
            }

            &--notimg {
                background: url($urlShort + '/posts-icons/post.svg') no-repeat $color-bg--image;
                background-position: center;
                transition: {
                    property: transform;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }

        &__swiper {
            width: 100%;
            overflow: visible;
        } 

        &__item {
            width: 470px;
        }

        &__infos-title {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--3;
            line-height: toRem(30);
            color: $color-text;
            margin: 20px 0 0 0; 
        }

        .news-pagination {
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 10px;
            height: 25px;
            padding: 6px 10px;
            background-color: $color-white;
            border-radius: $btn-spe-border-radius;
            z-index: 2;
            pointer-events: auto;
            width: fit-content;

            &__bullets {
                @include flex($alignItems: center);
                gap: 10px;

                .swiper-pagination-bullet {
                    @include size(12px);
                    margin: 0 !important;
                    opacity: 1 !important;
                    background-color: $color-bg--transparent;
                    border: 1px solid $color-main;
                    border-radius: 30px;
                    transition: {
                        property: width, background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-main;
                        border-color: $color-main;
                    }

                    &-active {
                        width: 22px;
                        background-color: $color-main;
                        border-color: $color-main;
                    }
                }
            }
        }

        &__buttons {
            @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
            width: fit-content;
            gap: 30px;
            margin-top: 30px;
        }

        &__button {
            background-color: $color-main;
            border-color: $color-main;
            margin: 0;

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                border-color: $color-main;
                color: $color-main;
            }
        }
    }

    //======================================================================================================
    // Events
    //======================================================================================================

    .home-events {
        background-color: $color-white;
        padding: 100px 0 80px;
        margin-top: -30px;
        border-radius: 30px 30px 0 0;

        &__container {
            height: 100%;
        }

        &__title {
            color: $color-fifth;
            
        }

        &__swiper {
            margin-top: -5px;
            overflow: visible;
            height: 560px;
        }

        &__link {
            height: 525px;
            background-color: $color-white;
            border-radius: $border-radius;
            box-shadow: 1px 1px 20px 0px #2828281A;
            transition: margin $duration ease-in-out;

            &:nth-child(odd) {
                margin-top: 35px;
            }

            &:hover, &:focus {
                .home-events__content .home-events__content__picto {
                    fill: $color-white;
                    background-color: $color-fifth;
                }

                .home-events__image img {
                    transform: scale(1.05);

                }
            }
        }

        .swiper-slide-active {
            margin-top:35px!important; // override of swiper's style
        }

        &__image {
            overflow: hidden;
            margin: 0;
            width: 100%;
            height: 345px;
            border-radius: $border-radius;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: {
                    property: transform;
                    duration: 0.5s;
                    timing-function: $timing;
                }
            }

            &--notimg {
                background: url($urlSpriteImage + '/events-icons/events.svg') no-repeat $color-bg--image;
                background-position: center;
                width: 100%;
            }
        }

        
        &__date {
            @include absolute($top: -70px, $left: 0);
            padding: 15px 20px;
            height: fit-content;
            border-radius: 15px 15px 15px 0;
            background-color: $color-fifth;
            @include flex($direction: row, $alignItems: flex-start, $justifyContent: space-between);
            gap: 10px;

            p {
                font-family: $font-family;
                font-size: toRem(22);  
                line-height: toRem(20);
                font-weight: $font-weight-normal;
                color: $color-white;
                text-transform: uppercase;
                @include flex($direction: column); 
            }

            &--day {
                font-family: $font-family--heading;
                font-weight: $font-weight-bold;
                font-size: toRem(24);
            }

            &--separator {
                @include size(20px);
                fill: $color-white;
            }
        }

        &__content {
            position: relative;
            display: flex;
            height: 180px;
            box-sizing: border-box;
            padding: 25px 50px 25px 30px;

            &__picto {
                @include absolute($bottom: 10px, $right: 10px);
                @include size(20px);
                fill: $color-fifth;
                background-color: $color-fourth;
                border-radius: $border-radius--round; 
                transition: {
                    property: background-color, fill, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }


        &__infos {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 15px;

            p {
                width: fit-content;
                padding: 5px 10px;
                background-color: $color-fourth;
                border-radius: 6px;
                color: $color-fifth;
                font-size: toRem(11);
                line-height: toRem(20);
                font-weight: $font-weight-bold;
                text-transform: uppercase;
            }

            &__title {
                font-family: $font-family--heading;
                font-size: toRem(24);
                font-weight: 700;
                list-style: toRem(28);
                color: $color-dark;
                position: relative;
                margin: 0;
            }
        }

        &__buttons {
            margin-top: 40px;
            display: flex;
            align-items: center;
            gap: 30px;
        }

        .events-pagination {
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 10px;
            height: 25px;
            padding: 6px 10px;
            background-color: $color-fourth;
            border-radius: $btn-spe-border-radius;
            z-index: 2;
            pointer-events: auto;
            width: fit-content;

            &__bullets {
                @include flex($alignItems: center);
                gap: 10px;

                .swiper-pagination-bullet {
                    @include size(12px);
                    margin: 0 !important;
                    opacity: 1 !important;
                    background-color: $color-bg--transparent;
                    border: 1px solid $color-fifth;
                    border-radius: 30px;
                    transition: {
                        property: width, background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-fifth;
                        border-color: $color-fifth;
                    }

                    &-active {
                        width: 22px;
                        background-color: $color-fifth;
                        border-color: $color-fifth;
                    }
                }
            }
        }

        &__button {
            padding: 0 25px;
            margin: 0;
        }
    }

    //======================================================================================================
    // Zone Kiosque + annuaires
    //======================================================================================================
    .zone-duo {
        @include flex($direction: row, $justifyContent: space-between);
        background-color: $color-white;
        padding: 20px 15px;
    }

    //======================================================================================================
    // Zone kiosque
    //======================================================================================================
    .kiosque {
        @include flex($direction: column);
        background-color: $color-light;
        padding: 105px 90px 70px 1500px;
        margin-left: -1500px;
        width: calc(700px + 1500px);
        height: 650px;
        border-radius: 0 30px 30px 0;

        &__title {
            margin-left: 330px;
            margin-bottom: -105px;
        }

        &__slide {
            @include flex($direction: row, $alignItems: flex-end, $justifyContent: space-between);
        }

        &__image {
            @include size(280px, 390px);
            border-radius: 5px;
            overflow: hidden;
            box-shadow: 1px 1px 15px 0px #2828281A;
            flex: none;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

        }

        &__content {
            .swiper-container {
                overflow: visible;
            }
        }

        &__content__container {
            @include size(350px, fit-content);
            background-color: $color-white;
            border-radius: $border-radius;
            padding: 40px;
            margin-left: -20px;
            margin-bottom: 15px;
            box-shadow: 1px 1px 20px 0px #2828281A;

        }

        &__content__title {
            font-family: $font-family--heading;
            font-size: toRem(28);
            font-weight: $font-weight-bold;
            color: $color-dark;
            margin: 0;
        }

        &__data {
            @include flex($direction: row, $alignItems: center, $justifyContent: flex-start);
            gap: 15px;
            margin-top: 15px;
            font-size: $font-size--text-small;
            line-height: toRem(24);
            color: $color-gray;

            svg {
                fill: $color-gray;
                @include size(20px);
            }

            &__item {
                @include flex($direction: row, $alignItems: center, $justifyContent: flex-start);

            }
        }

        &__content__buttons {
            margin-top: 20px;
            height: 40px;
            width: fit-content;
            @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
            gap: 10px;

            .btn {
                @include size(40px);
                border-radius: $border-radius--round;
                background-color: $color-main;
                border: $border-style $border-width $color-main;
                @include flex( $alignItems: center, $justifyContent: center);
                transition: {
                    property: background-color, border-color, color, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }

                svg {
                    fill: $color-white;
                    @include size(30px);

                    transition: {
                        property: fill;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    border-color: $color-main;

                    svg {
                        fill: $color-main;
                    }
                }
            }
        }


        &__buttons {
            margin-top: 40px;
            display: flex;
            align-items: center;
            gap: 30px;
        }

        &__button {
            background-color: $color-main;
            border-color: $color-main;
            margin: 0;

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                border-color: $color-main;
                color: $color-main;
            }
        }

        .kiosque-pagination {
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 10px;
            height: 25px;
            padding: 6px 10px;
            background-color: $color-white;
            border-radius: $btn-spe-border-radius;
            z-index: 2;
            pointer-events: auto;
            width: fit-content;

            &__bullets {
                @include flex($alignItems: center);
                gap: 10px;

                .swiper-pagination-bullet {
                    @include size(12px);
                    margin: 0 !important;
                    opacity: 1 !important;
                    background-color: $color-bg--transparent;
                    border: 1px solid $color-main;
                    border-radius: 30px;
                    transition: {
                        property: width, background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-main;
                        border-color: $color-main;
                    }

                    &-active {
                        width: 22px;
                        background-color: $color-main;
                        border-color: $color-main;
                    }
                }
            }
        }
    }


    //======================================================================================================
    // Zone annuaire
    //======================================================================================================
    .annuaire {
        @include flex($direction: column);
        width: 385px;
        background-color: $color-white;

        &__wrapper {
            margin-top: 30px;
            @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: space-between);
            gap: 20px;
        }

        &__link {
            position: relative;
            width: calc(50% - 10px);
            height: 130px;
            padding: 10px 20px;
            border-radius: $border-radius;
            border: $border-style $border-width $color-third;
            @include flex($direction: column, $alignItems: center, $justifyContent: space-between);
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }
            
            p {
                text-align: center;
                font-size: $font-size--text-small;
                line-height: toRem(16);
                max-width: 125px;
            }

            &:hover, &:focus {
                background-color: $color-third;
                border-color: $color-main;
            }

            &--large {
                position: relative;
                width: 100%;
                height: 200px;
                padding: 20px 25px;
                background-color: $color-sixth;
                @include flex($direction: column, $alignItems: flex-start, $justifyContent: flex-end);
                overflow: hidden;

                &::before {
                    content: '';
                    @include absolute($top: 0, $left: 0, $right: 0, $bottom: 0);
                    background: url($urlShortImage + 'elus.jpg') center center no-repeat;
                    background-size: cover;
                    opacity: 0.25;
                    z-index: 0;
                    
                }


                &:hover, &:focus {
                    background-color: $color-sixth;
                    .annuaire__picto {
                        fill: $color-white;
                        background-color: $color-main;
                    }
                }
            }
        }

        &__text {
            font-family: $font-family;
            font-size: toRem(22);
            line-height: toRem(24);
            font-weight: $font-weight-light;
            color: $color-white;
            text-transform: uppercase;
            position: relative;
            z-index: 1;

            &--top {
                font-family: $font-family--heading;
                font-weight: $font-weight-bold;
                font-size: toRem(30);
                line-height: toRem(28);
                text-transform: none;
            }
        }

        &__picto {
            @include absolute($bottom: 10px, $right: 10px);
            @include size(20px);
            fill: $color-main;
            background-color: rgba($color-light, 0.7);
            border-radius: $border-radius--round; 
            transition: {
                property: background-color, fill, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            &--top {
                fill: $color-white;
                position: absolute;
                top: 20px;
                right: 20px;
                @include size(30px, 43px);
            }
        }
    }

    //======================================================================================================
    // Zone Projets
    //======================================================================================================

    .zone-admin {
        padding-top: 100px;
        padding-bottom: 60px;
        background-color: $color-white;

        &__title {
            color: $color-fifth;
        }

        &__image {
            width: 100%;
            height: 420px;
            margin-top: 30px;
            border-radius: $border-radius;
            overflow: hidden;

            img {
                @include size(100%);
                object-fit: cover;
            }
        }

        &__info-title {
            position: relative;
            @include size(430px, 165px);
            padding: 40px;
            font-size: toRem(24);
            line-height: toRem(28);
            font-weight: $font-weight-bold;
            color: $color-dark;
            margin: -110px 0 0 50px;
            background-color: $color-white;
            border-radius: $border-radius;
            box-shadow: 1px 1px 20px 0px #2828281A;


        }

        &__picto {
            @include absolute($bottom: 10px, $right: 10px);
            @include size(20px);
            fill: $color-fifth;
            background-color: $color-fourth;
            border-radius: $border-radius--round; 
            transition: {
                property: background-color, fill, opacity;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &__button {
            &:hover, &:focus {
                .zone-admin__picto {
                    fill: $color-white;
                    background-color: $color-fifth;
                }
            }
        }
    }

    //======================================================================================================
    // Social Wall
    //======================================================================================================
    .reseaux {
        padding: 80px 0 90px;
        background-color: $color-light;
        border-radius: $border-radius $border-radius 0 0;

        &__container {
            &__sw {
                margin-top: 30px;
                width: 100%;
            }
        }

        #ff-stream-1 {
            @include size(100%, 390px);
            min-height: unset!important;
            background: transparent!important;
            padding: 0;

            .ff-header, .ff-loadmore-wrapper, .ff-item-meta {
                display: none!important;
            }

            .ff-stream-wrapper {
                @include size(calc(100% + 60px), 390px!important);
                margin: 0 0 0 -30px!important;
                padding: 0;
                

                .ff-item {
                    @include size(270px!important, 390px!important);
                    border-radius: $border-radius;
                    overflow: hidden;
                    background-color: $color-white!important;
                    box-shadow: 1px 1px 20px 0px #2828281A!important;


                    .picture-item__inner {
                        background-color: $color-bg--transparent!important;
                    }
                    
                    .ff-item-cont {
                        height: 355px!important;
                        overflow: hidden !important;

                        .ff-img-holder {
                            @include size(270px!important, 190px!important);
                            overflow: hidden;

                            img {                        
                                @include size(270px!important, 190px!important);
                                min-height: unset!important;
                                object-fit: cover!important;
                            }
                        }

                        .ff-content {
                            @include size(230px!important, 140px!important);
                            margin: 15px 20px 10px!important;
                            color: $color-text!important;
                            padding-top: 32px !important;
                            position: relative !important;

                            br {
                                content: "";
                                margin: 0.5em !important;
                                font-size: 8px;
                            }

                            &::before {
                                content: "Ville d’orly";
                                @include absolute($top: 0, $left: 0);
                                @include size(98px, 22px);
                                border-radius: 6px;
                                background-color: $color-light;
                                padding: 1px 8px 1px 22px;
                                color: $color-main;
                                font-size: 10px;
                                line-height: 20px;
                                text-transform: uppercase;
                                font-weight: $font-weight-semibold;
                                fill: $color-main;
                            }

                            &::after {
                                content: url($urlSpriteImage + "theme-icons/sw_facebook.svg") ;
                                fill: $color-main;
                                @include absolute($top: -1px, $left: 1px);
                                @include size(22px);
                            }
                        }
                    }

                    .ff-item-bar {
                        padding-top: 0!important;
                        border: 0 !important;
                    }
                }
            }
        }


        .ff-loadmore-wrapper {
            display: none !important;
        }

        &__buttons {
            margin-top: 40px;
        }

        &__button {
            background-color: $color-bg--transparent;
            border: 1px solid $color-main;
            color: $color-main;
            line-height: toRem(26);
            padding: 9px 28px;

            svg {
                fill: $color-main;
            }

            &:hover, &:focus {
                background-color: $color-main;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }
    }


    //======================================================================================================
    // Responsive
    //======================================================================================================

    // 1200
    @media screen and (max-width: $large) {


        .home__title {
            font-size: toRem(48);
        }

        .home-search {
            .search-popup__content {
                top: 310px;
            }
        }

        .home-slideshow {
            height: 500px;

            .slideshow {
                .c-pagination {
                    bottom: 110px;
                }
            }
        }

        .access {
            padding: 20px;
            margin-top: -45px;
        }

        .news {

            &__title {
                margin-left: 650px;
            }

            &__bloc {
                height: fit-content;
            }

            &__image {
                @include size(380px, 200px);

                &--large {
                    @include size(620px, 330px);
                    flex: none;
                }
            }

            &__text {
                padding: 55px 50px;
                font-size: $font-size--3;
                line-height: toRem(30);
                margin-bottom: 18px;
            }

            &__infos-title {
                font-size: $font-size--4;
                line-height: toRem(28);
            }

            &__buttons {
                margin-top: 40px;
            }
        }

        .home-events {
            &__image {
                height: 280px;
            }

            &__swiper {
                height: 490px;
            }

            &__link {
                height: 460px;
            }
        }

        .zone-duo {

        }

        .kiosque {
            width: calc(600px + 1500px);
            padding: 105px 60px 70px 1500px;

            &__content {
                &__title {
                    font-size: toRem(24);
                    line-height: torem(28);
                }

                &__container {
                    padding: 35px;
                    margin-left: -70px;
                    width: 330px;
                }
                
            }
        }

        .annuaire {
            width: 300px;
        }

        .zone-admin {
            padding-top: 80px;
        }

        .reseaux {
            #ff-stream-1 {
    
                .ff-stream-wrapper {
                    @include size(calc(100% + 40px), 390px!important);
                    margin-left: -20px!important;

                    .ff-item {
                        width: 220px !important;

                        .ff-item-cont {

                            .ff-img-holder {
                                @include size(220px!important, 190px!important);

                                img {
                                    @include size(220px!important, 190px!important);
                                }
                            }

                            .ff-content {
                                @include size(180px!important, 140px!important);
                            }

                        }
                    }
                }
            }
        }

    }


    // 960
    @media screen and (max-width: $medium) {

        .home-slideshow {
            position: relative;

            .slideshow {

                &--video {
                    video {
                        display: none;
                    }

                    img {
                        display: block;
                    }
                }

                .c-pagination {
                    bottom: 80px;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                    width: fit-content;
                }
            }
        }

        .access {
            flex-wrap: wrap;
            justify-content: center;
            gap: 12px;
        }


        .news {
            padding-top: 345px;
            margin-top: -270px;

            &__title {
                margin: 0;
            }

            &__posts {
                margin-top: 20px;
                gap: 55px;
            }

            &__bloc {
                flex-direction: column;
            }

            &__image {
                @include size(100%, 180px);

                &--large {
                    @include size(100%, 370px);
                    z-index: 2;
                }
            }

            &__text {
                padding: 50px 20px 35px;
                margin: -15px 0 0;
                width: 100%;
                height: fit-content;
                z-index: 0;
                border-radius: 0 0 $border-radius $border-radius;
            }

            &__buttons {
                margin-top: 35px;
            }
        }

        .home-events {
            padding-top: 50px;

            &__swiper {
                margin-top: 30px;
                height: 460px;
            }
    
            &__link {
    
                &:nth-child(odd) {
                    margin-top: 0;
                }
            }
    
            .swiper-slide-active {
                margin-top:0!important; // override of swiper's style
            }

            &__content {
                padding: 20px 50px 20px 30px;

            }
        }

        .zone-duo {
            flex-direction: column;
            gap: 60px;
        }

        .kiosque {
            width: calc(100% + 1500px);
        }

        .annuaire {
            width: 100%;

            &__wrapper {
                height: 280px;
                flex-direction: column;
                
            }

            &__link {
                width: calc(25% - 15px);

                &--large {
                    height: 100%;
                    width: calc(50% - 10px);

                
                }
            }
        }

        .zone-admin {
            &__info-title {
                width: 90%;
                margin: -70px auto 0;
            }
        }

        .reseaux {
            #ff-stream-1 {
                @include size(100%!important, 800px!important);

                .ff-stream-wrapper {
                    height: 800px!important;

                    .ff-item {
                        @include size(calc(50% - 30px)!important, 390px!important);

                        .ff-item-cont {

                            .ff-img-holder {
                                width: 100%!important;
                                height: 190px!important;
                            }

                            .ff-content {
                                @include size(calc(100% - 40px)!important, 140px!important);
                            }
                        }
                    }
                }
            }

        }
    }


    // 640
    @media screen and (max-width: $small) {

        .front-page {
            margin-top: -20px;
        }

        .home-slideshow {
            .slideshow {
                
                &__content {
                    left: 30px;
                    right: 30px;
                    width: auto;
                }

                .c-pagination {
                    bottom: 35px;
                }
            }
        }

        .home-search {
            .search-popup__content {
                top: 355px;
                @include size(300px, 40px);
            }

            .search-container input {
                height: 40px;
            }
        }

        .access {
            margin: 0; 
            padding: 40px calc(5% + 10px) 60px;
            width: 100%;
            max-width: 100%;
            border-radius: 40px 40px 0 0;
        }


        .news {
            padding-top: 50px;
            margin-top: -10px;
            z-index: 5;
            position: relative;

            &__posts {
                gap: 30px;
            }

            &__image {
                @include size(100%, 175px);

                &--large {
                    height: 180px;
                }
            }

            &__text {
                padding: 40px 20px 25px;
            }

            &__buttons {
                margin-top: 25px;
                flex-direction: column;
                align-items: flex-start;
                gap: 25px;
            }
        }

        .home-events {
            padding-top: 40px;
            z-index: 6;
            position: relative;

            &__buttons {
                flex-direction: column;
                align-items: flex-start;
            }

            &__button {
                height: 45px;
            }
        }

        .kiosque {
            width: calc(100% + 100px);
            height: fit-content;
            margin: 0 -50px;
            padding: 60px 50px 70px;
            
            border-radius: $border-radius $border-radius 0 0;


            &__title {
                margin: 0;
            }

            &__content {
                margin-top: 20px;
                width: 280px;

                .swiper-container {
                    overflow: visible;
                    @include size(100%);
                }
            }

            &__slide {
                flex-direction: column;
                width: 280px;
            }

            &__image {
                @include size(100%, 100%);
            }

            &__content__container {
                width: 280px;
                margin: -10px 0 0;
                padding: 30px 20px;
                box-sizing: border-box;
            }

            &__data {
                font-size: toRem(12);
            }

            &__buttons {
                margin-top: 30px;
                gap: 25px;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .annuaire {
            width: 100%;

            &__wrapper {
                @include size(100%, fit-content);
                justify-content: center;
                flex-direction: row;
            }

            &__link {
                @include size(140px, 130px);

                &--large {
                    width: 100%;
                    height: 220px;
                    margin: 0;
                }
            }
        }

        .zone-admin {

            &__title {
                margin: 0 calc(5% + 10px);
            }

            &__container {
                width: 100%;
                max-width: 100%;
                padding: 0;
            }

            &__image {
                height: 180px;
                width: 100%;
                border-radius: 0;
            }
        }

        .reseaux {
            #ff-stream-1 {
                height: 1620px!important;

                .ff-stream-wrapper {
                    height: 1620px!important;

                    .ff-item {
                        width: calc(100% - 40px)!important;
                        height: 390px !important;

                        .ff-item-cont {

                            .ff-img-holder img {
                                width: 100%!important;
                            }

                            .ff-content {
                            }
                        }

                    }
                }
            }


            &__button {
                margin-right: 0;
            }
        }
    }
}
